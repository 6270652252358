import { useUser as useAuth0User } from "@auth0/nextjs-auth0/client";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import Button from "components/Button";
import { Hamburger, X } from "components/Icons";
import AdminOnly from "components/RoleCheck/AdminOnly";
import StaffOnly from "components/RoleCheck/StaffOnly";
import styles from "./index.module.css";

const Header = ({
  background,
  disableNavigation,
  parentPage
}) => {
  const router = useRouter();

  const [showNavMenu, setShowNavMenu] = useState(false);

  const { user } = useAuth0User();

  const buttonProps = {};
  if(parentPage?.href) {
    buttonProps.href = parentPage.href;
  } else if(parentPage) {
    buttonProps.onClick = router.back;
  }

  const renderNavLinks = () => (
    <>
      <StaffOnly>
        <Link href="/incidents">Find a Claimant</Link>
        <Link href="/appointments">Appointments</Link>
        <Link href="/exams">Exams</Link>
        <Link href="/reports">Reports</Link>
      </StaffOnly>
      <AdminOnly>
        <Link href="/admin">Admin</Link>
      </AdminOnly>
      <Link href="/profile">{user?.name}</Link>
      {parentPage &&
        <Button {...buttonProps} style="white_toolbar">{parentPage.label}</Button>
      }
    </>
  );

  return (
    <div className={clsx(styles.root, { [styles.dark]: background === "dark" })}>
      {disableNavigation ?
        <div>Foremost Independent Exams</div>
      :
        <>
          <Link href="/">Foremost Independent Exams</Link>
          <div className={styles.navLinksMain}>
            {renderNavLinks()}
          </div>
          <div className={styles.hamburgerButton} onClick={() => setShowNavMenu(true)}>
            <Hamburger />
          </div>
        </>
      }
      <div className={clsx(styles.navMenu, { [styles.showMenu]: showNavMenu })}>
        <div className={styles.closeButton} onClick={() => setShowNavMenu(false)}>
          <X />
        </div>
        <div className={styles.navLinksMobile}>
          {renderNavLinks()}
        </div>
      </div>
    </div>
  );
};

export default Header;
