import { STAFF_ROLES } from "lib/consts";
import RoleCheck from ".";

const StaffOnly = ({ children }) => {
  return (
    <RoleCheck roles={STAFF_ROLES}>
      {children}
    </RoleCheck>
  );
};

export default StaffOnly;
