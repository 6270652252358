import { ROLES } from "lib/consts";
import RoleCheck from ".";

const { ADMIN } = ROLES;

const AdminOnly = ({
  children,
  redirectIfFailed,
}) => {
  return (
    <RoleCheck
      redirectIfFailed={redirectIfFailed}
      roles={[ADMIN]}
    >
      {children}
    </RoleCheck>
  );
};

export default AdminOnly;
