import clsx from "clsx";
import Header from "../Header";
import styles from "./index.module.css";

const Layout = ({
  background,
  children,
  disableNavigation,
  parentPage
}) => {
  return (
    <div className={clsx(styles.root, { [styles.dark]: background === "dark" })}>
      <Header background={background} disableNavigation={disableNavigation} parentPage={parentPage} />
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Layout;
